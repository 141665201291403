import { graphql, Link } from 'gatsby'
import React from 'react'
import Layout from '../../components/Layout'
import * as styles from '../../styles/gallery.module.css'
import Img from 'gatsby-image'

import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Card } from 'react-bootstrap'

export default function Gallery({ data }) {
  console.log(data)
  const gallery = data.allMarkdownRemark.nodes
  
  return (
    <Layout>
      <Container>
        <div className={styles.portfolio}>
          <h2>Gallery</h2>
            <h3>Foto Travel Umroh & Haji</h3>
              <div className={styles.projects}>
                {gallery.map(gallery => (
                  <Card>
                    <Link to={"/gallery/" + gallery.frontmatter.slug} key={gallery.id} className="nav-link" activeClassName="active">
                      <div>
                        <Img fluid={gallery.frontmatter.thumb.childImageSharp.fluid}/>
                        <h3>{ gallery.frontmatter.title }</h3>
                        <p>{ gallery.frontmatter.stack }</p>
                      </div>
                    </Link>
                  </Card>
                 ))}
                  </div>
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query GalleryPage {
    allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
      nodes {
        frontmatter {
          title
          stack
          slug
          date
          thumb {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        id
      }
    }
  }
`